(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/middleware-payment-confirm/assets/get-fraction-interaction-props.js') >= 0) return;  svs.modules.push('/components/payment/middleware-payment-confirm/assets/get-fraction-interaction-props.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  constants
} = svs.components.payment.common;
const {
  Currency: currency
} = svs.utils.format;
const getFractionInteractionProps = async _ref => {
  var _svs;
  let {
    paymentInfo,
    payment
  } = _ref;
  const {
    documentId
  } = ((_svs = svs) === null || _svs === void 0 || (_svs = _svs.cmsData) === null || _svs === void 0 || (_svs = _svs.data) === null || _svs === void 0 || (_svs = _svs.rulesAndTerms) === null || _svs === void 0 ? void 0 : _svs.marketplaceRulesAndTerms[0]) || {};
  const termsUrl = "/dokument/".concat(documentId);
  const price = payment.getTotalPrice();
  const title = 'Betala';
  const addonMessage = ["Vill du betala ".concat(currency(price), " kr f\xF6r 1 andel i spelet?"), 'Om spelet inte lämnas in eller om det avbryts får du pengarna tillbaka.', "Genom att k\xF6pa en andel godk\xE4nner du <a href=\"".concat(termsUrl, "\" rel=\"noopener noreferrer\" target=\"_BLANK\">Villkor f\xF6r att Spela tillsammans</a>")].join('<br /><br />');
  const paymentInfoData = paymentInfo.get();
  let paymentInfoMessage;
  if (paymentInfoData.length) {
    const prePaymentInfo = paymentInfoData.filter(info => info.type === constants.paymentInfoType.PRE);
    paymentInfoMessage = prePaymentInfo.map(info => info.message).join(' ');
  }
  const customMessage = addonMessage || paymentInfoMessage;
  return _objectSpread({
    title
  }, customMessage ? {
    customMessage
  } : {});
};
setGlobal('svs.components.payment.middleware.utils.getFractionInteractionProps', getFractionInteractionProps);

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/middleware-payment-confirm/assets/get-wager-interaction-props.js') >= 0) return;  svs.modules.push('/components/payment/middleware-payment-confirm/assets/get-wager-interaction-props.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  constants
} = svs.components.payment.common;
const {
  Currency: currency
} = svs.utils.format;
const {
  getAccountBalance
} = svs.components.marketplaceShared.getBalance;
const {
  groupTypes
} = svs.components.marketplaceData.constants;
const {
  isWager
} = svs.components.payment.createWager.utils;
const getProductsString = payableItems => {
  const products = [...new Set(payableItems.reduce((products, wager) => products.concat(isWager(wager) ? wager.getProductsInBet() : []), []))];
  if (products.length <= 1) {
    return products.join('');
  }
  return "".concat(products.slice(0, -1).join(', '), " och ").concat(products.slice(-1).pop());
};
const getWagerInteractionProps = async _ref => {
  let {
    paymentInfo,
    payment
  } = _ref;
  const payableItems = payment.getPayableItems();
  const marketplaceWager = payableItems.find(wager => wager.activatedDrawId);
  const ownRowsWagers = payableItems.filter(wager => wager.hasOwnRows);
  let marketplaceDrawName;
  let role;
  let addonMessage;
  if (ownRowsWagers.length > 1) {
    addonMessage = 'Notera att maxgränsen för spelet är uppnått. Spelet kommer att delas upp och hittas under Mina spel.';
  }
  if (marketplaceWager) {
    var _marketplaceDraw$data, _marketplaceDraw$data2;
    role = marketplaceWager === null || marketplaceWager === void 0 ? void 0 : marketplaceWager.role;
    const activatedDrawId = marketplaceWager.activatedDrawId;
    const productId = marketplaceWager.bets[0].productId;
    const drawNumber = marketplaceWager.bets[0].drawNumber;
    const marketplaceDraw = await getAccountBalance.getBalance(role, productId, drawNumber, activatedDrawId, false);
    marketplaceDrawName = role === groupTypes.PRIVATE ? marketplaceDraw === null || marketplaceDraw === void 0 || (_marketplaceDraw$data = marketplaceDraw.data) === null || _marketplaceDraw$data === void 0 ? void 0 : _marketplaceDraw$data.GroupName : marketplaceDraw === null || marketplaceDraw === void 0 || (_marketplaceDraw$data2 = marketplaceDraw.data) === null || _marketplaceDraw$data2 === void 0 ? void 0 : _marketplaceDraw$data2.name;
  }
  const productsStr = getProductsString(payableItems);
  const price = payment.getTotalPrice();
  const payableItemsCount = payment.count();
  const gamesStr = payableItemsCount > 1 ? 'dina' : 'ett';
  const title = "Vill du betala ".concat(gamesStr, " spel p\xE5 ").concat(productsStr, " f\xF6r ").concat(currency(price), " kr").concat(marketplaceDrawName ? " \xE5t ".concat(marketplaceDrawName) : '', "?");
  const paymentInfoData = paymentInfo.get();
  let paymentInfoMessage;
  if (paymentInfoData.length) {
    const prePaymentInfo = paymentInfoData.filter(info => info.type === constants.paymentInfoType.PRE);
    paymentInfoMessage = prePaymentInfo.map(info => info.message).join(' ');
  }
  const customMessage = addonMessage || paymentInfoMessage;
  return _objectSpread({
    title,
    role
  }, customMessage ? {
    customMessage
  } : {});
};
setGlobal('svs.components.payment.middleware.utils.getWagerInteractionProps', getWagerInteractionProps);

 })(window);